.error-notfound img {
    height: 300px;
    width: 300px;
}

.error-notfound button {
    transition: background-color 0.5s, color 0.5s;
}

.error-notfound button:hover {
    background-color: #303F9F;
    color: #fff;
}

@media (max-width: 767px) {
    .error-notfound {
        padding: 10px;
    }
}