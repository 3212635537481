.mainn-container{
    padding: 30px 40px;
    background: #fff;
    border-radius: 10px;
    /* height: 100%; */

}

.mainn-container {
    height: 100%;
}

.mainn-form input[type="text"] {
    height: 40px;
    font-size: 12px;
    border: 2px solid #00D4C3;
    border-radius: 47px;
}

.mainn-form button[type="submit"] {
    background: #00D4C3;
    color: white;
    border-radius: 43px;
    height: 40px;
    width: 50%;
    font-weight: 700;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
    margin: 0 auto;
}

.mainn-form button[type="submit"]:hover {
    background: #00D4C3;
}